<script>
  export let token = "";
  import ProductsList from "./products/ProductsList.svelte";
  import FileMakerProductList from "./file_maker_products/FileMakerProductList.svelte";
  import LanguageList from "./languages/LanguageList.svelte";
  import PixelHtml from "./pixel_html/PixelHTML.svelte";

  let isMenuOpen = false;
  let page = "showProducts";
  function toggleMenu() {
    isMenuOpen = !isMenuOpen;
  }
</script>

<div>
  <button class="button-menu {isMenuOpen ? 'open' : ''}" on:click={toggleMenu}>
    <span>
      {#if isMenuOpen} &times; {:else} &#9776; {/if}
    </span>
  </button>

  <div class="menu {isMenuOpen ? 'slide-enter' : 'slide-exit'}">
    <p style="margin-top: 120px;" class="ptitle">Products</p>
    &nbsp;&nbsp;
    <p
      class="subptitle"
      on:keydown={(event) => {
        if (event.key === "Enter" || event.key === "Space") {
          page = "showProducts";
        }
      }}
      on:click={() => (page = "showProducts")}
    >
      List
    </p>
    <br />
    <p class="ptitle">SKU File Maker</p>
    &nbsp;&nbsp;
    <p
      class="subptitle"
      on:keydown={(event) => {
        if (event.key === "Enter" || event.key === "Space") {
          page = "showSkuFileMakerProduct";
        }
      }}
      on:click={() => (page = "showSkuFileMakerProduct")}
    >
      List
    </p>
    <br />
    <p class="ptitle">Language</p>
    &nbsp;&nbsp;
    <p
      class="subptitle"
      on:keydown={(event) => {
        if (event.key === "Enter" || event.key === "Space") {
          page = "showLaguages";
        }
      }}
      on:click={() => (page = "showLaguages")}
    >
      List
    </p>
    <br />
    <p class="ptitle">Pixel HTML</p>
    &nbsp;&nbsp;
    <p
      class="subptitle"
      on:keydown={(event) => {
        if (event.key === "Enter" || event.key === "Space") {
          page = "showPixelHtml";
        }
      }}
      on:click={() => (page = "showPixelHtml")}
    >
      List
    </p>
  </div>
  {#if page == "showProducts"}
    <ProductsList {token} />
  {/if}
  {#if page == "showSkuFileMakerProduct"}
    <FileMakerProductList {token} />
  {/if}
  {#if page == "showLaguages"}
    <LanguageList {token} />
  {/if}
  {#if page == "showPixelHtml"}
    <PixelHtml {token} />
  {/if}
</div>

<style>
  .menu {
    position: fixed;
    top: 0;
    left: -250px;
    bottom: 0;
    width: 250px;
    background-color: #f0f0f0;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: left 0.3s ease;
    z-index: 1;
  }
  /* Animation classes */
  .menu.slide-enter {
    animation: slideIn 0.3s forwards;
  }
  .menu.slide-exit {
    animation: slideOut 0.3s forwards;
  }
  @keyframes slideIn {
    from {
      left: -250px;
    }
    to {
      left: 0;
    }
  }
  @keyframes slideOut {
    from {
      left: 0;
    }
    to {
      left: -250px;
    }
  }
  .button-menu {
    position: fixed;
    top: 90px;
    left: 20px;
    background-color: #a7a4a4;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: left 0.3s ease;
  }
  /* Adjust the position of the button with the menu */
  .button-menu.open {
    left: 270px;
  }
  /* Style the button span (icon) */
  .button-menu span {
    font-size: 20px;
  }
  .menu .ptitle {
    font-size: 21px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
  }
  .menu .subptitle {
    margin-left: 20px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
  }
</style>
