<script>
  import Swal from "sweetalert2";
  import Editor from "../../../components/Editor.svelte";
  import Loader from "../../../components/Loader.svelte";
  import { fade } from "svelte/transition";
  import { onMount } from "svelte";

  export let token;

  let isLoading = false;
  let pixelHtmlContent = "";
  let host = process.env.config.environment === "development" 
    ? process.env.config.localServerUrl 
    : "";

  let options = {
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike", "link", "video"],
        ["blockquote", "code-block"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ direction: "rtl" }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ["clean"],
      ],
    },
    placeholder: "Enter your pixel HTML code here...",
    theme: "snow",
  };

  function onChangePixelHtmlValue(event) {
    pixelHtmlContent = event.detail.html;
  }

  function isEmpty(string) {
    const aux = document.createElement("div");
    aux.innerHTML = string;
    return aux.innerText.trim() === "";
  }

  onMount(async () => {
    await fetchPixelHtml();
  });

  async function fetchPixelHtml() {
    isLoading = true;
    try {
      const response = await fetch(`${host}/api/v2/admin/pixel-html`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authentication: `Basic ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        pixelHtmlContent = data?.content || "";
      } else {
        await Swal.fire("Error", "Failed to fetch pixel HTML content", "error");
      }
    } catch (error) {
      console.error("Error fetching pixel HTML:", error);
      await Swal.fire("Error", "An error occurred while fetching data", "error");
    } finally {
      isLoading = false;
    }
  }

  async function updatePixelHtml() {
    const result = await Swal.fire({
      title: "Confirm Update",
      text: "Are you sure you want to update the Pixel HTML content?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, update",
      cancelButtonText: "Cancel",
    });

    if (!result.isConfirmed) return;

    isLoading = true;
    try {
      const response = await fetch(`${host}/api/v2/admin/pixel-html`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authentication: `Basic ${token}`,
        },
        body: JSON.stringify({
          attributes: {
            content: pixelHtmlContent,
          },
        }),
      });

      if (response.ok) {
        await Swal.fire("Success", "Pixel HTML updated successfully", "success");
        await fetchPixelHtml();
      } else {
        await Swal.fire("Error", "Failed to update Pixel HTML", "error");
      }
    } catch (error) {
      console.error("Error updating pixel HTML:", error);
      await Swal.fire("Error", "An error occurred while updating", "error");
    } finally {
      isLoading = false;
    }
  }
</script>

<Loader visible={isLoading}>
  <div class="processing">Loading Pixel HTML...</div>
</Loader>

<div class="pixel-html-container">
  <h2>Pixel HTML Editor</h2>
  <p>Use this editor to manage the pixel tracking code that will be injected into your pages.</p>

  <div class="editor-wrapper">
    <div class="editor-container">
      <textarea 
        bind:value={pixelHtmlContent} 
        placeholder="Enter raw HTML here..."
      />
    </div>
    <div class="action-buttons">
      <button class="refresh-button" on:click={fetchPixelHtml}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8"></path>
          <path d="M3 3v5h5"></path>
          <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16"></path>
          <path d="M16 16h5v5"></path>
        </svg>
        Refresh
      </button>
      <button class="save-button" on:click={updatePixelHtml}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
          <polyline points="17 21 17 13 7 13 7 21"></polyline>
          <polyline points="7 3 7 8 15 8"></polyline>
        </svg>
        Update
      </button>
    </div>
  </div>
</div>

<style>
  .pixel-html-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  p {
    color: #666;
    margin-bottom: 20px;
  }

  .editor-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;
  }
  
  .editor-container {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
  }
  
  textarea {
    width: 100%;
    min-height: 300px;
    padding: 15px;
    font-family: monospace;
    font-size: 14px;
    line-height: 1.5;
    border: none;
    resize: vertical;
    background-color: #f8f9fa;
  }
  
  .action-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .save-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .save-button:hover {
    background-color: #3d8b40;
  }
  
  .refresh-button {
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ddd;
  }
  
  .refresh-button:hover {
    background-color: #e0e0e0;
  }
  
  .processing {
    font-size: 24px;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px black;
  }
</style>